import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/Output.css";
import moment from "moment";

function decodeQuotedPrintable(str) {
  return str.replace(/=\r\n/g, "").replace(/=([A-F0-9]{2})/gi, (match, p1) => {
    return String.fromCharCode(parseInt(p1, 16));
  });
}

function decodeUtf8(str) {
  try {
    return decodeURIComponent(escape(str));
  } catch (e) {
    console.error("UTF-8 string çözülmesi sırasında hata oluştu:", e);
    return str;
  }
}

function decodeMimeHeader(header) {
  const mimePattern = /=\?([^?]+)\?([BQbq])\?([^?]+)\?=/g;
  return header.replace(mimePattern, (match, charset, encoding, text) => {
    let decodedText;
    if (encoding.toUpperCase() === "B") {
      decodedText = atob(text);
    } else {
      decodedText = decodeQuotedPrintable(text);
    }
    return decodeURIComponent(escape(decodedText));
  });
}

function decodeHeader(header) {
  let decodedHeader = decodeMimeHeader(header);

  decodedHeader = decodeQuotedPrintable(decodedHeader);

  decodedHeader = decodeUtf8(decodedHeader);

  decodedHeader = decodedHeader.replace(/MIME-Version: 1.0/gi, "").trim();

  const dateMatch = decodedHeader.match(/Date:\s*(.*)/i);
  const fromMatch = decodedHeader.match(/From:\s*(.*)/i);
  const subjectMatch = decodedHeader.match(/Subject:\s*(.*)/i);

  const date = dateMatch
    ? moment(dateMatch[1], "ddd, DD MMM YYYY HH:mm:ss ZZ").format(
        "YYYY-MM-DD HH:mm:ss"
      )
    : "";
  const from = fromMatch ? fromMatch[1].split("<")[0].trim() : "";

  let subject = subjectMatch ? decodeMimeHeader(subjectMatch[1]) : "";
  subject = decodeUtf8(subject).trim();

  return { date, content: `${date} ${subject}` };
}

function Output({ emails = [], onSelectMail }) {
  const sortedEmails = emails
    .map((email) => ({
      ...email,
      decoded: decodeHeader(email.subject),
    }))
    .sort((a, b) => moment(b.decoded.date).diff(moment(a.decoded.date)));

  if (!Array.isArray(sortedEmails)) {
    return <p>Xəta: E-maillər məlumatı mövcud deyil.</p>;
  }

  return (
    <div className="container bg-white p-3 rounded shadow-sm my-2 email-list-container">
      <h5 className="py-1">E-maillərin Siyahısı</h5>
      {sortedEmails.length === 0 ? (
        <p>Heç bir e-mail tapılmadı.</p>
      ) : (
        <div className="email-list-container">
          <ul className="list-group email-list">
            {sortedEmails.map((email, index) => (
              <li
                key={email.id}
                className={`list-group-item email-list-item ${
                  index >= 3 ? "hidden-email" : ""
                }`}
                onClick={() => onSelectMail(email.id)}
                style={{ cursor: "pointer", fontSize: "0.9rem" }}
                dangerouslySetInnerHTML={{ __html: email.decoded.content }}
              />
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default Output;
