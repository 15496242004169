// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.email-list-container {
  max-height: 300px;
  overflow: hidden;
}

.email-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.email-list-item {
  cursor: pointer;
  font-size: 0.9rem;
}

.hidden-email {
  display: none;
}

.email-list-item {
  border-bottom: 1px solid #acabab;
}

.email-list-item:last-child {
  border-bottom: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/styles/Output.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,UAAU;EACV,SAAS;AACX;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".email-list-container {\n  max-height: 300px;\n  overflow: hidden;\n}\n\n.email-list {\n  list-style: none;\n  padding: 0;\n  margin: 0;\n}\n\n.email-list-item {\n  cursor: pointer;\n  font-size: 0.9rem;\n}\n\n.hidden-email {\n  display: none;\n}\n\n.email-list-item {\n  border-bottom: 1px solid #acabab;\n}\n\n.email-list-item:last-child {\n  border-bottom: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
