import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const LoginPage = () => {
  const [mail, setMail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      const response = await axios.post("https://api.kodsky.net/login", {
        mail,
        password,
      });
      localStorage.setItem("authToken", response.data.access_token);
      localStorage.setItem("refreshToken", response.data.refresh_token);
      localStorage.setItem("userType", response.data.user_type);
      navigate("/dashboard");
    } catch (err) {
      setError("Giriş uğursuz oldu. Xahiş edirəm məlumatlarınızı yoxlayın.");
    }
  };

  return (
    <div className="container mt-5">
      <h2 className="text-dark">Giriş</h2>
      {error && <div className="alert alert-danger">{error}</div>}
      <div className="mb-3">
        <label htmlFor="mail" className="form-label text-dark">
          Email
        </label>
        <input
          type="email"
          className="form-control"
          id="mail"
          value={mail}
          onChange={(e) => setMail(e.target.value)}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="password" className="form-label text-dark">
          Şifrə
        </label>
        <input
          type="password"
          className="form-control"
          id="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <button onClick={handleLogin} className="btn btn-primary">
        Giriş
      </button>
    </div>
  );
};

export default LoginPage;
