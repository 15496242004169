import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

function decodeQuotedPrintable(str) {
  return str
    .replace(/=\r\n/g, "") // CRLF karakterlerini kaldırır
    .replace(/=\n/g, "")   // LF karakterlerini kaldırır
    .replace(/\r\n/g, "")  // Normal satır sonlarını da kaldırır
    .replace(/=([A-F0-9]{2})/gi, (match, p1) => String.fromCharCode(parseInt(p1, 16)))
    .replace(/â€™/g, "’")  // Tek tırnak karakteri (’)
    .replace(/â€“/g, "–")  // Uzun çizgi karakteri (–)
    .replace(/â€œ/g, "“")  // Açık çift tırnak (“)
    .replace(/â€/g, "”")  // Kapalı çift tırnak (”)
    .replace(/Â/g, " ")    // Fazladan boşluk karakteri
    .replace(/Ã¼/g, "ü")   // Ü harfi
    .replace(/Ã¶/g, "ö")   // Ö harfi
    .replace(/ÅŸ/g, "ş")   // Ş harfi
    .replace(/Ä±/g, "ı")   // I harfi
    .replace(/Ä/g, "ğ")   // Ğ harfi
    .replace(/Ã§/g, "ç")   // Ç harfi
    .replace(/Ä/g, "ğ")   // Ğ harfi
    .replace(/Ã©/g, "é")   // É harfi
    .replace(/â€¦/g, "…"); // Üç nokta karakteri (…)
}

function cleanContent(content) {
  return content
    .replace(/\\r\\n/g, "") // \r\n karakterlerini kaldırır
    .replace(/\\n/g, "")    // \n karakterlerini kaldırır
    .replace(/\\/g, "");    // Ters eğik çizgileri (\) kaldırır
}

function Content({ selectedEmailId, emailAddress }) {
  const [emailDetails, setEmailDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchEmailContent = async () => {
      if (!selectedEmailId) return;

      try {
        setLoading(true);
        const response = await fetch(
          `https://api.kodsky.net/mail?email=${encodeURIComponent(
            emailAddress
          )}&id=${encodeURIComponent(selectedEmailId)}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const jsonResponse = await response.json();
        const quotedPrintableContent = jsonResponse.body || "";
        console.log("Quoted-Printable Content:", quotedPrintableContent);

        const decodedContent = decodeQuotedPrintable(quotedPrintableContent);
        const cleanedContent = cleanContent(decodedContent);
        console.log("Cleaned Content:", cleanedContent);

        const parser = new DOMParser();
        const doc = parser.parseFromString(cleanedContent, "text/html");

        // CSS'yi bul ve ekle
        const cssLinks = doc.querySelectorAll("link[rel='stylesheet']");
        cssLinks.forEach((link) => {
          const href = link.href;
          if (href.startsWith(window.location.origin)) {
            const style = document.createElement("link");
            style.rel = "stylesheet";
            style.href = href;
            document.head.appendChild(style);
          }
        });

        setEmailDetails({
          content: doc.documentElement.innerHTML,
        });
        setError("");
      } catch (error) {
        console.error("Error fetching email content:", error);
        setError("Error fetching email details. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchEmailContent();
  }, [selectedEmailId, emailAddress]);

  if (!selectedEmailId) {
    return (
      <div className="container bg-white p-4 rounded shadow-sm text-center">
        <p>Detallara baxmaq üçün e-poçt seçin.</p>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="container bg-white p-4 rounded shadow-sm text-center">
        <p>Yüklənir...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container bg-white p-4 rounded shadow-sm text-center">
        <p className="text-danger">{error}</p>
      </div>
    );
  }

  return (
    <div className="container bg-white p-4 rounded shadow-sm">
      <div dangerouslySetInnerHTML={{ __html: emailDetails?.content }} />
    </div>
  );
}

export default Content;
