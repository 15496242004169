import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../src/App.css';

function Input({ setEmails, onEmailUpdate }) {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false); // Loading durumunu ekleyin

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Form gönderme başladı
    try {
      const response = await fetch(`https://api.kodsky.net/mails?email=${email}`, { timeout: 10000 });
      if (!response.ok) {
        throw new Error('Şəbəkə cavabı uyğun deyil');
      }
      const data = await response.json();
      if (data.header) {
        const emails = Object.keys(data.header).map(key => ({
          id: key,
          subject: decodeHeader(data.header[key]),
          date: new Date().toLocaleDateString()
        }));
        setEmails(emails);
        onEmailUpdate(email); // Email adresi burada güncelleniyor
        setError(''); // Əvvəlki xətanı təmizlə
      } else {
        setError('E-maillər tapılmadı və ya cavab formatı düzgün deyil');
      }
    } catch (error) {
      setError('E-mailləri əldə etməkdə xəta baş verdi. Xahiş edirik yenidən cəhd edin.');
    } finally {
      setLoading(false); // Yükleme tamamlandı
    }
  };

  const decodeHeader = (header) => {
    return header
      .replace(/=\?UTF-8\?Q\?/i, '')
      .replace(/\?=/i, '')
      .replace(/_/g, ' ')
      .replace(/=([A-Fa-f0-9]{2})/g, (match, p1) => String.fromCharCode(parseInt(p1, 16)));
  };

  return (
    <div className="container bg-white p-4 inputHeight rounded shadow-sm">
      <h5 className="mb-2">E-mailləri Axtar</h5>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="emailInput" className="form-label">E-mail Ünvanını Daxil Edin</label>
          <input
            type="email"
            className="form-control"
            id="emailInput"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="btn btn-primary" disabled={loading}>
          {loading ? 'Gözləyin...' : 'Axtar'}
        </button>
      </form>
      {error && <p className="text-danger mt-3">{error}</p>}
    </div>
  );
}

export default Input;
