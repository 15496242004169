import React, { useState } from 'react';
import Input from './Input';
import Output from './Output';
import Content from './Content';
import Guide from './Guide';
import 'bootstrap/dist/css/bootstrap.min.css';

function Home() {
  const [emails, setEmails] = useState([]);
  const [selectedEmailId, setSelectedEmailId] = useState(null);
  const [emailAddress, setEmailAddress] = useState("");

  const handleSelectMail = (id) => {
    setSelectedEmailId(id);
  };

  const handleEmailUpdate = (address) => {
    setEmailAddress(address);
  };

  return (
    <div className="container mt-4 my-2">
      <div className="row">
        <div className="col-lg-3 col-md-4 mb-3">
          <Input setEmails={setEmails} onEmailUpdate={handleEmailUpdate} />
        </div>
        <div className="col-lg-9 col-md-8">
          <Guide />
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12">
          <Output emails={emails} onSelectMail={handleSelectMail} />
        </div>
      </div>
      <div className="mt-4">
        <Content
          selectedEmailId={selectedEmailId}
          emailAddress={emailAddress}
        />
      </div>
    </div>
  );
}

export default Home;
