import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

function Guide() {
  return (
    <div className="bg-white p-4 rounded shadow-sm h-100">
      <h5 className="mb-3">İstifadə Qaydaları</h5>
      <ol>
        <li>Aldığınız hesabın mail adresini sayta daxil edin Axtar'a klikləyin</li>
        <li>Ekranda açılan ən son göndərilmiş maili seçin</li>
        <li>Açılan pəncərədə alt hissədə sizə verilən profil adınızın, kodu daxil edəcəyiniz cihazın adının yazıldığından əmin olun, daha sonra <strong>"Kodu Al"</strong> və ya <strong>"Evet ben gönderdim"</strong> yazılı qırmızı rəngli butona klikləyin</li>
        <li>Sistem sizi ikinci bir sayta yönləndirəcək, Telefon üçün 4 rəqəmli kodu proqrama daxil edin. TV üçün isə qara rəngli <strong>"Güncellemeni onayla"</strong> yazılı butona klikləyin</li>      </ol>
    </div>
  );
}

export default Guide;
