import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const [email, setEmail] = useState("");
  const [from, setFrom] = useState("");
  const [password, setPassword] = useState("");
  const [subject, setSubject] = useState([""]); // Subject'i dizi olarak başlatıyoruz
  const [error, setError] = useState("");
  const [userMails, setUserMails] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [username, setUsername] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const navigate = useNavigate();

  // Çıkış işlemi
  const handleLogout = async () => {
    try {
      const refreshToken = localStorage.getItem("refreshToken");
      await axios.post(
        "https://api.kodsky.net/logout",
        {
          refresh_token: refreshToken,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
          withCredentials: true,
        }
      );

      localStorage.removeItem("refreshToken");
      localStorage.removeItem("authToken");
      localStorage.removeItem("userType");
      setTimeout(() => {
        navigate("/login");
      }, 500);
    } catch (err) {
      console.error(
        "Çıkış başarısız oldu:",
        err.response ? err.response.data : err.message
      );
      setError("Çıkış uğursuz oldu. Xahiş edirəm yenidən cəhd edin.");
    }
  };

  // Mail ekleme işlemi
  const handlePostMail = async () => {
    try {
      await axios.post(
        "https://api.kodsky.net/mail",
        {
          email,
          from,
          password,
          subject,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );

      setError("");
      fetchUserMails();
    } catch (err) {
      setError(
        "Mail əlavə etmək uğursuz oldu. Xahiş edirəm məlumatları yoxlayın və yenidən cəhd edin."
      );
    }
  };

  const fetchUserMails = async () => {
    try {
      const response = await axios.get("https://api.kodsky.net/user_mail", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Accept: "*/*",
        },
        withCredentials: true,
      });
      setUserMails(response.data.mails || []);
    } catch (err) {
      console.error("İstifadəçi maillərini çəkməkdə xəta baş verdi:", err);
      setError(
        "Mailləri çəkməkdə xəta baş verdi. Xahiş edirəm yenidən cəhd edin."
      );
    }
  };

  const handleDeleteMail = async (email) => {
    try {
      await axios.delete(
        `https://api.kodsky.net/user_mail/${encodeURIComponent(email)}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Accept: "*/*",
          },
          params: {
            email: email,
          },
          withCredentials: true,
        }
      );

      setUserMails((prevMails) =>
        prevMails.filter((mail) => mail.email !== email)
      );

      fetchUserMails();
    } catch (err) {
      console.error("Maili silməkdə xəta baş verdi:", err);
      setError(
        "Maili silməkdə xəta baş verdi. Xahiş edirəm yenidən cəhd edin."
      );
    }
  };

  const handlePostUser = async () => {
    try {
      await axios.post(
        "https://api.kodsky.net/add_user",
        {
          username,
          password: userPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );

      setError("");
      setUsername("");
      setUserPassword("");
    } catch (err) {
      setError(
        "Kullanıcı əlavə etmək uğursuz oldu. Xahiş edirəm məlumatları yoxlayın və yenidən cəhd edin."
      );
    }
  };

  const handleSubjectChange = (index, value) => {
    const newSubjects = [...subject];
    newSubjects[index] = value;
    setSubject(newSubjects);
  };

  const addSubjectField = () => {
    setSubject([...subject, ""]);
  };

  useEffect(() => {
    fetchUserMails();
    const userType = localStorage.getItem("userType");
    setIsAdmin(userType === "Admin");
  }, []);

  const renderUserMails = () => {
    if (userMails.length > 0) {
      return userMails.map((mail, index) => (
        <li
          key={index}
          className="list-group-item d-flex justify-content-between align-items-center"
        >
          <span>
            <strong>Mövzu:</strong> {mail.subject.join(", ")}
          </span>{" "}
          <span>
            <strong>Email:</strong> {mail.email}
          </span>
          <span>
            <strong>Kimdən:</strong> {mail.from}
          </span>
          <button
            className="btn btn-danger btn-sm"
            onClick={() => handleDeleteMail(mail.email)}
          >
            Sil
          </button>
        </li>
      ));
    } else {
      return <p className="text-dark">Mail tapılmadı.</p>;
    }
  };

  return (
    <div className="container mt-5">
      <h2 className="text-dark">Dashboard</h2>
      <button onClick={handleLogout} className="btn btn-danger mb-3 text-white">
        Çıxış
      </button>

      <h3 className="text-dark">Mail Əlavə Et</h3>
      {error && <div className="alert alert-danger">{error}</div>}
      <div className="mb-3">
        <label htmlFor="email" className="form-label text-dark">
          Email
        </label>
        <input
          type="email"
          className="form-control"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="from" className="form-label text-dark">
          Kimdən
        </label>
        <input
          type="email"
          className="form-control"
          id="from"
          value={from}
          onChange={(e) => setFrom(e.target.value)}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="password" className="form-label text-dark">
          Şifrə
        </label>
        <input
          type="password"
          className="form-control"
          id="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      {subject.map((subj, index) => (
        <div key={index} className="mb-3">
          <label htmlFor={`subject${index}`} className="form-label text-dark">
            Mövzu {index + 1}
          </label>
          <input
            type="text"
            className="form-control"
            id={`subject${index}`}
            value={subj}
            onChange={(e) => handleSubjectChange(index, e.target.value)}
          />
        </div>
      ))}
      <button
        type="button"
        onClick={addSubjectField}
        className="btn btn-secondary text-white me-2"
      >
        Mövzu Əlavə Et
      </button>
      <button onClick={handlePostMail} className="btn btn-primary text-white">
        Mail Əlavə Et
      </button>

      {isAdmin && (
        <>
          <h3 className="mt-5 text-dark">Kullanıcı Əlavə Et</h3>
          {error && <div className="alert alert-danger">{error}</div>}
          <div className="mb-3">
            <label htmlFor="username" className="form-label text-dark">
              Kullanıcı Adı
            </label>
            <input
              type="text"
              className="form-control"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="userPassword" className="form-label text-dark">
              Şifrə
            </label>
            <input
              type="password"
              className="form-control"
              id="userPassword"
              value={userPassword}
              onChange={(e) => setUserPassword(e.target.value)}
            />
          </div>
          <button
            onClick={handlePostUser}
            className="btn btn-primary text-white"
          >
            Kullanıcı Əlavə Et
          </button>
        </>
      )}

      <h3 className="mt-5 text-dark">İstifadəçi Mailləri</h3>
      <ul className="list-group">{renderUserMails()}</ul>
    </div>
  );
};

export default Dashboard;
